// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Page from "../server/Page.js";
import * as Render from "./Render.js";
import * as Socket from "./Socket.js";
import * as Protocol from "../server/Protocol.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as S$RescriptStruct from "rescript-struct/src/S.js";

var pageInfo;

if (Render.pageInfoElem !== undefined) {
  pageInfo = S$RescriptStruct.parseJsonStringWith(Caml_option.valFromOption(Render.pageInfoElem).textContent, Protocol.pageInfoStruct);
} else {
  throw {
        RE_EXN_ID: "Not_found",
        Error: new Error()
      };
}

var pageProps;

if (Render.pagePropsElem !== undefined) {
  var elem = Caml_option.valFromOption(Render.pagePropsElem);
  var json = JSON.parse(elem.textContent);
  elem.remove();
  pageProps = json;
} else {
  pageProps = null;
}

if (pageInfo.TAG === "Ok") {
  var match = await Page.render(pageInfo._0.src, false, pageProps);
  if (match !== undefined) {
    Render.hydratePage(match[0]);
  } else {
    throw {
          RE_EXN_ID: "Not_found",
          Error: new Error()
        };
  }
} else {
  throw {
        RE_EXN_ID: "Not_found",
        Error: new Error()
      };
}

function refetchCurrentPage() {
  var href = window.location.href;
  var payload = Protocol.serializeRequest({
        TAG: "Refetch",
        url: href
      });
  if (payload.TAG === "Ok") {
    Socket.socket.send(payload._0);
    return ;
  }
  console.log("Err");
}

window.addEventListener("popstate", (function (param) {
        refetchCurrentPage(undefined);
      }));

window.addEventListener("_s_p", (function (evt) {
        var customEvent = evt.detail;
        var payload = Protocol.serializeRequest({
              TAG: "Prefetch",
              url: customEvent.href
            });
        if (payload.TAG === "Ok") {
          Socket.socket.send(payload._0);
          return ;
        }
        console.log("Err");
      }));

window.addEventListener("_s_l", (function (evt) {
        var customEvent = evt.detail;
        var payload = Protocol.serializeRequest({
              TAG: "Refetch",
              url: customEvent.href
            });
        if (payload.TAG === "Ok") {
          Socket.socket.send(payload._0);
          return ;
        }
        console.log("Err");
      }));

export {
  pageInfo ,
  pageProps ,
  refetchCurrentPage ,
}
/* pageInfo Not a pure module */
